@use '../config/' as *;
.waf-row-podcast {
  .waf-athlete-podcast {
    margin-top: 24.6rem;
  }
}
.load-bg-pattern {
  .waf-compareplayer {
    &::before {
      @include bgImg("cssimages/eventlisting-bg.png", center, cover, no-repeat);
    }
  }
}
.waf-athlete-podcast {
  padding-block: 0 var(--space-14);
  border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
  margin-inline: var(--space-2-neg);
  margin-bottom: 0;
  @extend %bg-neutral-100;
  .layout-wrapper {
    max-width: unset;
  }
  .waf-head {
    padding: var(--space-10) var(--space-4) 18.3rem;
    background: linear-gradient(99deg, hsl(var(--hsl-neutral-0) / .15) 0.29%, hsl(var(--hsl-neutral-0) / 0) 56.37%), var(--medium-black-0);
    box-shadow: 0 .4rem 2.5rem 0 hsl(var(--hsl-pure-black-0) / .2);
    translate: 0 -19rem;
    @include default-wrapper();
    @extend %border-radius-m;
  }
  .title {
    letter-spacing: -.048rem;
    @include title-with-icon(shape, trophy, 24, neutral-0);
    @extend %neutral-0;
    @extend %title-40;
    &::before {
      @extend %neutral-5;
    }
  }
  .desc {
    @extend %neutral-20;
    @extend %body-mb;
  }
  .head-logo {
    @extend %d-none;
  }
  .waf-body {
    margin-top: -28rem;
    @extend %ml-4;
  }
  .card {
    &-list {
      padding-right: var(--container-white-space);
      @include card-count(1.15, var(--space-4));
      @extend %ml-4;
    }
    &-item {
      max-width: 32rem;
      @extend %relative;
    }
    &-thumb {
      @extend %mb-6;
      &-img {
        aspect-ratio: 1;
        object-fit: cover;
        @extend %border-radius-s;
      }
    }
    &-label {
      @include truncate-text(2, 67.2);
      @extend %neutral-5;
      @extend %title-40;
    }
    &-link {
      @include position-combo(inset);
      @extend %border-radius-m;
    }
  }
}
.waf-athlete-hero {
  padding-block: 0 var(--space-10);
  margin-inline: var(--space-2-neg);
  margin-bottom: 0;
  @extend %player-list;
  @extend %bg-neutral-100;
  .layout-wrapper {
    max-width: unset;
  }
  .waf-head {
    // height: 44.5rem;
    height: 50rem;
    background: url('/static-assets/images/cssimages/athlete-home-bg.webp?v=#{$image-version}') top / cover no-repeat;
    isolation: isolate;
    @extend %relative;
    @extend %common-gradient;
    &::before,
    &:after {
      pointer-events: none;
      @extend %zindex-pattern;
    }
  }
  .head-wrap {
    @include default-wrapper();
    @extend %h-100;
    @extend %pb-2;
    @extend %flex-column-fe-n;
  }
  .title {
    @extend %mb-4;
    @extend %neutral-5;
    @extend %title-30;
  }
  .desc {
    @extend %neutral-0;
    @extend %body-l;
  }
  .waf-body {
    background-color: hsl(var(--hsl-neutral-0) / .3);
    @include default-wrapper();
    @extend %p-4;
    @extend %border-radius-m;
    @extend %mt-10;
  }
  .head-title {
    @extend %neutral-5;
    @extend %title-40;
  }
  .btn-site {
    @extend %flex-n-c;
    @extend %neutral-5;
    @extend %gap-2;
    &:after {
      @include icon(arrow-right, 20);
    }
    &:hover {
      @extend %neutral-20;
    }
    &:focus {
      @extend %neutral-0;
    }
    &:disabled {
      @extend %neutral-40;
    }
  }
  .btn-close {
    @include position-combo("y-center", 0);
    .btn-text {
      @extend %font-0;
      &::before {
        @include icon(close, 16);
        @extend %neutral-100;
      }
    }
  }
  .search {
    &-wrapper {
      @extend %mt-8;
      @extend %relative;
    }
    &-input {
      @extend %w-100;
      @extend %p-6-4;
      @extend %border-radius-s;
      @extend %bg-neutral-0;
      @extend %neutral-70;
      @extend %text-l;
      &-wrap {
        @extend %relative;
      }
    }
  }
  .player {
    &-content-body {
      @extend %d-none;
      &.active {
        max-height: 50rem;
        border-radius: 0 0 var(--border-radius-m) var(--border-radius-m);
        z-index: map-get($map: $zindex, $key: athlete-search-list);
        box-shadow: 0 .4rem 2.5rem 0 hsl(var(--hsl-pure-black-0) / .2);
        overflow-y: auto;
        @include custom-scroll();
        @include position(calc(100% + var(--space-2)), null, null, 0);
        @extend %w-100;
        @extend %p-4;
        @extend %d-block;
        @extend %bg-neutral-0;
      }
    }
    &-item {
      @extend %relative;
      &:hover {
        @extend %bg-neutral-5;
      }
    }
    &-footer {
      a {
        @extend %d-block;
        @extend %w-100;
        @extend %h-100;
        @extend %position-t-l;
      }
    }
    // &-name {
    //   a::after {
    //     content: "";
    //     @include position-combo(inset);
    //   }
    // }
    // &-bio {
    //   @extend %d-none;
    // }
  }
}
.waf-athlete-info {
  padding-block: var(--space-20);
  margin-inline: var(--space-2-neg);
  margin-bottom: 0;
  @extend %event-card-big;
  .waf-head {
    @extend %mb-8;
  }
  .head-wrap .title {
    color: var(--neutral-100);
    @include title-with-icon(shape, document-blank, 22);
    @extend %title-40;
  }
  .waf-body {
    margin-right: var(--space-4-neg)
  }
  .card {
    &-list {
      padding-right: var(--container-white-space);
      @include card-count(1.15, var(--space-4));
    }
    &-item:not(:last-child) {
      margin-bottom: 0;
    }
  }
}
.waf-compareplayer {
  padding-block: var(--space-14) var(--space-18);
  margin-bottom: 0;
  &::before {
    height: 100%;
  }
  .waf-head {
    margin-top: 0;
  }
  .title {
    @include title-with-icon(shape, face-smile, 22, neutral-0);
  }
  .desc {
    @extend %neutral-0;
    @extend %body-mb;
  }
  .tabs {
    margin-bottom: var(--space-14);
  }
  .card-action {
    display: none;
  }
  .more-actions {
    margin-block: var(--space-16) 0;
  }
  .input-wrapper {
    @extend %relative;
    &:has(.search-text) {
      .form-input {
        @extend %pr-14;
      }
      .btn-link {
        @extend %flex;
      }
    }
    .btn-link {
      @include position-combo("y-center", 1.6rem);
      &:hover {
        .btn-text::before {
          @extend %primary-55;
        }
      }
      &:active {
        .btn-text::before {
          @extend %primary-85;
        }
      }
      .btn-text {
        @extend %primary-50;
        @extend %body-mb;
        @extend %capitalize;
      }
      &::after {
        @extend %d-none;
      }
    }
  }
}
.waf-squad {
  padding-block: 0;
  margin-inline: var(--space-2-neg);
  margin-bottom: 0;
  .layout-wrapper {
    max-width: unset;
  }
  .waf-head {
    aspect-ratio: .8;
    border-radius: 0 0 4rem 4rem;
    background: url(/static-assets/images/cssimages/featured-athletes-bg.webp?v=#{$image-version}) center / cover no-repeat;
    isolation: isolate;
    @extend %flex-column-fe-n;
    @extend %relative;
    @extend %common-gradient;
    &::before,
    &::after {
      @extend %zindex-pattern;
    }
    &::after {
      border-radius: 0 0 4rem 4rem;
    }
  }
  .head-wrap {
    @include default-wrapper();
    @extend %pb-21;
    @extend %neutral-0;
  }
  .title {
    @include title-with-icon(shape, face-smile, 22, neutral-0);
    @extend %title-40;
  }
  .desc {
    @extend %body-mb;
  }
  .waf-body {
    @extend %mt-14-neg;
  }
  .player {
    &-item-list {
      @include card-count(1.15, var(--space-4));
      @extend %px-4;
    }
    &-item {
      max-width: 33rem;
      height: 39.6rem;
      border: .1rem solid var(--neutral-20);
      @extend %border-radius-m;
      @extend %relative;
      @extend %bg-neutral-0;
      &:hover .player-head .img {
        scale: 1.05;
      }
      &.women .player-thumbnail {
        border: .4rem solid var(--secondary-70);
      }
    }
    &-head {
      border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
      overflow: hidden;
      @extend %h-50;
      @extend %relative;
      @extend %bg-primary-50;
      &::after {
        content: "";
        background: linear-gradient(180deg, hsl(var(--hsl-primary-50) / 0) 0%, hsl(var(--hsl-primary-50) / 1) 100%);
        pointer-events: none;
        @include position-combo(inset);
      }
      .img {
        aspect-ratio: 1/1;
        // margin: 5% auto 0;
        object-fit: cover;
        transition: scale .3s ease-in;
        @extend %w-100;
        &[src^="/static-assets/images/svg/vector.svg"] {
          filter: invert(70%) sepia(96%) saturate(700%) hue-rotate(200deg) brightness(80%) contrast(250%);
        }
      }
    }
    &-body {
      height: calc(50% + 4.8rem);
      padding: 0 var(--space-6) var(--space-6);
    }
    &-thumbnail {
      width: 9.6rem;
      aspect-ratio: 1/1;
      border: .4rem solid var(--primary-50);
      margin-top: -4.8rem;
      // @extend %p-3;
      @extend %circle-radius;
      @extend %mb-2;
      @extend %relative;
      @extend %bg-neutral-0;
    }
    &-img {
      aspect-ratio: 1/1;
      @extend %circle-radius;
      &[src^="/static-assets/images/svg/vector.svg"] {
        filter: invert(70%) sepia(96%) saturate(700%) hue-rotate(200deg) brightness(80%) contrast(250%);
      }
    }
    &-info {
      height: calc(100% - 9.6rem - var(--space-2));
      @extend %flex-column;
    }
    &-name a::before {
      content: "";
      @include position-combo(inset);
      @extend %border-radius-m;
    }
    &-meta {
      margin-top: auto;
      @extend %flex-sb-c;
    }
  }
  .name {
    @extend %title-40;
  }
  .meta {
    &-origin {
      @extend %flex-n-c;
      @extend %gap-2;
      img {
        object-fit: cover;
        width: 2.4rem;
        aspect-ratio: 1;
        @extend %circle-radius;
      }
    }
    &-date {
      @extend %flex-n-c;
    }
  }
  .swiper {
    &-button-prev,
    &-button-next {
      @extend %d-none;
    }
  }
  .team-name,
  .info-label,
  .info-value {
    line-height: 1;
    @extend %neutral-70;
  }
}
.waf-athlete-standings {
  padding: var(--space-14) 0 26.5rem;
  margin-inline: var(--space-2-neg);
  margin-bottom: 0;
  @extend %ranking-table;
  @extend %standings-all;
  .waf-head {
    @extend %mb-8;
  }
  .title {
    @include title-with-icon(shape, trophy, 22);
    @extend %title-40;
    &::before {
      color: var(--neutral-40)
    }
  }
  .sub-title {
    @extend %neutral-70;
    @extend %body-mb;
  }
  .head-tab {
    @extend %d-none;
  }
  .selected-title {
    display: none;
  }
  .select {
    &-list {
      border-bottom: .1rem solid var(--neutral-20);
      position: unset;
      flex-direction: row;
      gap: 0;
      overflow-x: auto;
      @extend %flex;
      .select-item {
        width: auto;
        height: 6.4rem;
        padding: 0 var(--space-6);
        border-bottom: 0;
        background-color: transparent;
        @extend %flex-c-c;
        &.active {
          @extend %relative;
          &::before {
            content: "";
            height: .3rem;
            @include position-combo("bl");
            @extend %w-100;
            @extend %bg-primary-50;
          }
          .text {
            @extend %neutral-100;
          }
        }
        .text {
          width: max-content;
          line-height: 1;
          @extend %neutral-70;
          @extend %text-l;
        }
      }
    }
  }
  .tabs {
    border-bottom: .1rem solid var(--neutral-20);
    position: unset;
    flex-direction: row;
    gap: 0;
    overflow-x: auto;
    @extend %flex;
    .tab-item {
      &.active {
        @extend %relative;
        &::before {
          content: "";
          height: .3rem;
          @include position-combo("bl");
          @extend %w-100;
          @extend %bg-primary-50;
        }
        .tab-text {
          @extend %neutral-100;
        }
      }
    }
    .tab-name {
      width: auto;
      height: 6.4rem;
      padding: 0 var(--space-6);
      border-bottom: 0;
      background-color: transparent;
      @extend %flex-c-c;
      .tab-text {
        width: max-content;
        line-height: 1;
        @extend %neutral-70;
        @extend %text-l;
      }
    }
  }
  .table {
    @extend %relative;
    &-data {
      width: auto;
      &:first-child {
        @include position(null, null, 100%, 0);
      }
      &.ranking,
      &.info,
      &.time {
        width: auto;
      }
      &.time {
        flex-grow: 1;
      }
      &.info {
        max-width: 52%;
      }
    }
    &-body {
      @extend %flex-column;
      @extend %gap-6;
      @extend %mt-6;
    }
    &-wrapper {
      display: block;
      padding-block: 0;
    }
    &-row {
      margin-top: calc((9/16)*100%);
      border-radius: 0 0 1.6rem 1.6rem;
      justify-content: flex-start;
      padding-block: 0;
      @include border(1, neutral-20);
      @extend %neutral-0;
      &-wrapper {
        padding-block: var(--space-14) var(--space-6);
      }
      .card-name {
        max-width: 100%;
      }
      &:not(:first-child) {
        gap: var(--space-0);
        .card {
          &-logo {
            width: 2.4rem;
            height: 2.4rem;
          }
        }
        .time {
          padding-right: 0;
        }
      }
      &:last-child {
        overflow: unset;
      }
      .table-data {
        .text {
          font-size: 1.8rem;
        }
      }
    }
    &-footer {
      margin-top: 0;
      @include position-combo(inset);
      a {
        @include position-combo(inset);
        @extend %border-radius-m;
        @extend %font-0;
        &::after {
          content: unset;
        }
      }
    }
  }
  .card-banner {
    aspect-ratio: 16/9;
    border-radius: 1.6rem 1.6rem 0 0;
    @extend %w-100;
    @extend %hidden;
    .image {
      object-fit: cover;
      @extend %w-100;
      @extend %h-100;
    }
  }
}
@include mq(col-tablet) {
  .waf-athlete-podcast {
    padding-bottom: 0;
    border-radius: var(--border-radius-xl) var(--border-radius-xl) 0 0;
    .waf-head {
      padding: var(--container-white-space) var(--container-white-space) 18.7rem;
      display: grid;
      gap: var(--space-4);
      grid-template-columns: auto 24%;
      translate: 0 -13rem;
    }
    .title {
      margin-bottom: var(--space-4);
      font-size: 4rem;
      line-height: 1.2;
    }
    .desc {
      font-size: 1.6rem;
    }
    .head-logo {
      max-width: 27rem;
      display: block;
    }
    .waf-body {
      @include default-wrapper();
    }
    .card {
      &-list {
        padding-right: 0;
        margin-inline: calc(var(--container-white-space) / 2);
        > * {
          width: calc(100% / 3 - ((2) * var(--space-4) / 3));
        }
      }
      &-item {
        max-width: unset;
      }
    }
  }
  .waf-athlete-hero {
    padding-bottom: var(--space-6);
    .waf-head {
      max-width: 100%;
      height: 60rem;
      min-height: 45rem;
      // aspect-ratio: 2.32;
    }
    .head-wrap {
      padding-bottom: var(--space-16);
    }
    .title,
    .desc {
      width: 50%;
    }
    .title {
      margin-bottom: var(--space-6);
      font-size: 5.6rem;
    }
    .waf-body {
      padding: var(--space-6);
      flex-wrap: wrap;
      @include flex-config(flex, null, space-between);
    }
    .head-title {
      margin-bottom: 0;
    }
    .search-wrapper {
      flex-basis: 100%;
    }
    .player {
      &-content-body {
        max-height: 61rem;
      }
      &-item {
        height: unset;
        display: flex;
        max-width: 117.6rem;
        margin: 0 auto;
      }
      &-info {
        align-items: center;
        gap: var(--space-2) var(--space-6);
        justify-content: unset;
      }
      &-name {
        flex-grow: unset;
      }
    }
  }
  .waf-athlete-info {
    padding-block: 0 var(--space-24);
    .waf-head {
      margin-bottom: var(--space-16);
    }
    .waf-body {
      margin-right: 0
    }
    .head-wrap .title {
      font-size: 4rem;
      line-height: 1.2;
    }
    .card-list {
      padding-right: 0;
      flex-wrap: nowrap;
      @include card-count(3, var(--space-4));
    }
  }
  .waf-squad {
    padding-bottom: var(--space-24);
    .waf-head {
      max-width: 100%;
      min-height: 45rem;
      aspect-ratio: 2.11;
    }
    .head-wrap {
      padding-bottom: 10rem;
    }
    .title {
      margin-bottom: var(--space-4);
      font-size: 4rem;
      line-height: 1.2;
    }
    .desc {
      width: 30%;
      min-width: 33rem;
      font-size: 1.6rem;
    }
    .waf-body {
      @include default-wrapper();
    }
    .player {
      &-content-body {
        overflow-x: clip;
        overflow-y: visible;
      }
      &-item-list {
        padding-inline: 0;
        gap: 0;
        overflow-x: clip;
        overflow-y: visible;
      }
      &-item {
        max-width: unset;
      }
    }
    .swiper {
      &-button-prev,
      &-button-next {
        display: flex;
        top: -9rem;
        transform: unset;
        &::after {
          font-size: 2.2rem;
        }
        &:hover {
          background-color: var(--neutral-70);
        }
        &:focus {
          border-color: var(--primary-85);
          background-color: var(--neutral-70);
        }
      }
      &-button-prev {
        right: calc(4.8rem + var(--space-2));
        left: unset;
      }
      &-button-next {
        top: -9rem;
        right: 0;
      }
      &-pagination {
        width: fit-content;
        height: fit-content;
        top: -6.5rem;
        right: calc(9.6rem + var(--space-8));
        left: unset;
        color: var(--neutral-0);
        font-size: 1.6rem;
      }
    }
  }
  .waf-athlete-standings {
    padding-block: var(--space-24) 22rem;
    .title {
      margin-bottom: var(--space-4);
      font-size: 4rem;
      line-height: 1.2;
    }
    .sub-title {
      width: 33rem;
      font-size: 1.6rem;
    }
    .table-body {
      @include card-count(2, var(--space-4), wrap);
    }
    .table-row {
      margin-top: calc(((9/16)*100%) / 2);
      .table-data {
        &.time {
          .text {
            font-size: 1.8rem;
          }
        }
      }
    }
  }
  .waf-compareplayer {
    .player-name {
      flex-grow: unset;
    }
    .player-info {
      justify-content: unset;
    }
  }
}
@include mq(col-lg) {
  .load-bg-pattern {
    .waf-compareplayer {
      &::before {
        @include bgImg("cssimages/pattern/head-to-head-bg-web.png");
      }
    }
  }
  .waf-compareplayer {
    padding-block: var(--space-24) 19rem;
    .layout-wrapper {
      margin-top: 0;
    }
    .waf-head {
      width: calc(100% - 23rem - var(--space-6));
    }
    .title {
      font-size: 4rem;
      line-height: 1.2;
    }
    .desc {
      font-size: 1.6rem;
    }
    .tab {
      &-section {
        position: relative;
      }
      &s {
        margin-bottom: 0;
        transform: translate(0, -100%);
        @include position(var(--space-14-neg), 0);
      }
      &-container-wrapper {
        padding: var(--space-6);
        border-radius: var(--border-radius-l);
        position: relative;
        background: hsl(var(--hsl-neutral-0) / .3);
      }
      &-head-wrapper {
        position: unset;
        gap: 0 var(--space-16);
        &::before {
          content: unset;
        }
      }
    }
    .card {
      &-item {
        margin-bottom: 0;
      }
      &-action {
        display: flex;
      }
    }
    .more-actions {
      margin-top: 0;
      translate: -50% 0;
      @include position(calc(100% + var(--space-8)), null, null, 50%);
    }
  }
  .waf-athlete-standings {
    .table-body {
      @include card-count(3, var(--space-4), wrap);
    }
    .table-row {
      margin-top: calc(((9/16)*100%) / 3);
    }
  }
}
@include mq(col-desktop) {
  .waf-athlete-podcast .card-list {
    > * {
      width: calc(100% / 4 - ((3) * var(--space-4) / 4));
    }
  }
  .waf-athlete-info .card-list {
    @include card-count(4, var(--space-6));
  }
  .waf-squad {
    .head-wrap {
      padding-bottom: 17rem;
    }
    .waf-body {
      margin-top: -13rem;
    }
  }
  .waf-athlete-standings {
    .table-body {
      @include card-count(4, var(--space-6), wrap);
    }
    .table-row {
      margin-top: calc(((9/16)*100%) / 4);
    }
  }
}
@include mq(col-xl) {
  .waf-athlete-standings {
    .table {
      &-row {
        .table-data {
          .text {
            font-size: 2rem;
          }
        }
      }
    }
  }
}