@use "../config/" as *;
.waf-compareplayer {
    padding-top: var(--header-height);
    @extend %relative;
    @extend %mx-2-neg;
    &:before {
        content: '';
        height: calc(var(--header-height) + 130rem);
        pointer-events: none;
        rotate: 180deg;
        border-radius: 1.6rem 1.6rem 0 0;
        @include position-combo(inset);
        @extend %bg-primary-50;
        @extend %zindex-pattern;
    }
    .waf-head {
        @extend %my-14;
    }
    .title {
        @extend %title-30;
        @extend %neutral-0;
    }
    .tabs {
        margin: var(--space-3) auto;
        width: max-content;
        @extend %p-1;
        @extend %rounded-radius;
        @extend %flex-c-c;
        @extend %bg-neutral-5;
    }
    .tab {
        &-item {
            &.active {
                .tab-name {
                    padding-inline: var(--space-7);
                    @extend %btn-fill-text-icon;
                }
            }
            &:not(.active) {
                .tab-name {
                    .tab-text,
                    .text {
                        @extend %primary-50;
                    }
                }
            }
        }
        &-name {
            min-width: 11.5rem;
            height: 4rem;
            justify-content: center;
            @extend %px-3;
            @extend %flex-n-c;
            @extend %gap-2;
            @extend %text-mb;
        }
    }
    .tab-search-wrapper {
        @extend %flex-sb-c;
        @extend %gap-4;
        @extend %relative;
        &::after {
            content: "VS";
            order: 0;
            @extend %text-l;
            @extend %neutral-0
        }
        input {
            border-radius: 1rem;
            @include truncate-text(1);
            @extend %bg-neutral-0;
            @extend %p-4;
            @extend %w-100;
            @extend %text-l;
            @extend %neutral-70
        }
        .search-option {
            width: calc(50% - var(--space-4));
            @extend %relative;
            &:last-child {
                order: 1;
            }
        }
        .input-options-list {
            max-height: 45rem;
            box-shadow: 0 .4rem 2.5rem 0 hsl(var(--hsl-pure-black-0)/0.2);
            overflow-y: auto;
            border-radius: 0 0 1.6rem 1.6rem;
            z-index: var(--z-content);
            @include position(calc(5.4rem + var(--space-2)), 0, null, 0);
            @include custom-scroll();
            @extend %x-hidden;
            @extend %bg-neutral-0;
            @extend %d-none;
            &.active {
                @extend %flex-column;
            }
        }
        .btn-link {
            @extend %d-none;
        }
    }
    .card {
        &-item {
            @extend %bg-neutral-0;
            @extend %border-radius-m;
            @extend %mb-4;
        }
        &-thumbnail {
            @extend %d-none;
        }
        &-logo {
            width: 9.6rem;
            height: 9.6rem;
            aspect-ratio: 1/1;
            margin-top: -7.2rem;
            border: 0.4rem solid;
            @extend %circle-radius;
            @extend %bg-neutral-0;
            @extend %mb-4;
            @extend %flex;
            .player-image {
                flex-shrink: 0;
            }
        }
        &-content-wrapper {
            margin-top: 7.2rem;
            display: grid;
            grid-template-columns: auto 11rem;
            @extend %bg-neutral-0;
            @extend %p-6;
            @extend %border-radius-m;
        }
        &-content {
            grid-area: 2/1;
            @extend %neutral-100;
            .card-info {
                @extend %flex;
                @extend %gap-2;
                @extend %neutral-70;
                @extend %mb-4;
            }
        }
        &-name {
            @include truncate-text(1);
            @extend %title-50;
            @extend %neutral-100;
        }
        &-action {
            grid-area: 2/2;
            @extend %flex-fe-fs;
        }
        &-data-list {
            grid-area: 4/ span 2;
            @include grid(5, var(--space-4));
            @extend %bg-neutral-5;
            @extend %border-radius-s;
            @extend %p-2;
        }
        &-data-item {
            @extend %flex-column-c-c;
            &:first-child {
                grid-column: 1/ span 2;
                border-right: 0.1rem solid var(--neutral-70);
            }
        }
    }
    .count {
        @extend %title-50;
    }
    .label {
        @extend %text-m;
    }
    .country-flag {
        @extend %flex-c-c;
        @extend %gap-2;
        img {
            width: 1.6rem;
            height: 1.6rem;
            aspect-ratio: 1/1;
            @extend %circle-radius;
        }
    }
    .btn {
        &-link {
            @extend %p-0;
            @extend %btn-default;
            @extend %gap-2;
            @extend %flex;
            &:after {
                @include icon(arrow-right, 19);
            }
        }
        &-more {
            max-width: 22rem;
            @extend %w-100;
            @extend %btn-fill-dark-icon;
            @extend %gap-2;
            &:after {
                @include icon(arrow-right, 19);
            }
            & > * {
                @extend %text-mb;
            }
        }
    }
    .player-image {
        @extend %circle-radius;
    }
    .men {
        .card-logo {
            border-color: var(--secondary-70);
        }
    }
    .women {
        .card-logo {
            border-color: var(--primary-70);
        }
    }
    .stats {
        &-list {
            @extend %bg-neutral-0;
            @extend %border-radius-m;
            @extend %hidden;
            &:not(:last-child) {
                @extend %mb-14;
            }
            .title {
                @extend %title-40;
                @extend %neutral-100;
                @extend %p-4;
            }
            > .title {
                @extend %bg-neutral-10;
                @extend %text-center;
            }
        }
        &-item {
            @extend %flex-sb-c;
            &:not(:last-child) {
                border-bottom: 0.1rem solid var(--neutral-20);
            }
            .value {
                @extend %title-50;
                @extend %neutral-100;
                @extend %p-4;
            }
            .title {
                @extend %text-center;
                @extend %body-l;
                @extend %m-0;
                @extend %flex-column;
                &.highlight {
                    font-weight: 700;
                }
            }
            .text {
                @extend %text-l;
            }
        }
    }
    .more-actions {
        @extend %flex-c-c;
        @extend %my-4
    }
    @extend %player-list;
}
@include mq(col-lg) {
    .waf-compareplayer {
        .title {
            font-size: 5.6rem;
        }
        .layout-wrapper {
            margin-top: 14rem;
        }
        &::before {
            rotate: unset;
            transform: rotateX(-180deg);
        }
        .tabs {
            margin: 0 0 7.6rem auto;
        }
        .player-item {
            height: unset;
            .player-info {
                align-items: center;
            }
            .name {
                font-size: 2.4rem;
            }
        }
        .tab {
            &-head-wrapper {
                position: relative;
                @include grid(2, var(--space-16));
                &::before {
                    content: '';
                    width: calc(100% + 2rem);
                    height: calc(100% + 10rem);
                    pointer-events: none;
                    border-radius: 2.4rem;
                    background: hsl(var(--hsl-neutral-0)/0.3);
                    z-index: var(--z-pattern);
                    @include position(-10rem, 0, 0, -1rem);
                }
            }
            &-search-wrapper {
                margin-bottom: var(--space-8);
            }
            &-stats-wrapper {
                margin-top: 5.6rem;
            }
            &-item.active .tab-name {
                min-height: unset;
            }
        }
        .card {
            &-thumbnail {
                display: flex;
                aspect-ratio: 16/9;
                img {
                    border-radius: 1.6rem;
                }
            }
            &-item {
                background: transparent;
            }
            &-content-wrapper {
                margin-top: -3rem;
                position: relative;
            }
        }
        .more-actions {
            grid-area: 2/span 2;
        }
        .name,
        .count {
            font-size: 3.2rem;
        }
        .stats-item {
            .value {
                font-size: 3.2rem;
            }
        }
    }
}